<template>
    <div class="full-width-overflow">
        <div class="description-section container-xl">
            <div class="row">
                <div class="col-md-12 col-lg-6">
                    <div class="d-flex flex-column description-block">
                        <div class="container-image">
                            <img src="/images/logo-pec-pec.png" alt="logo" />
                        </div>
                        <div class="description-block">
                            <h2 v-dompurify-html="title.text" class="title-description"></h2>
                        </div>
                        <div v-for="(itemDescription, index) in htmlDescription" :key="index" v-dompurify-html="itemDescription.text" class="description-text" />
                    </div>
                </div>
                <div class="col-md-12 col-lg-6 d-none d-lg-flex align-items-center">
                    <div class="container-image-fura">
                        <img src="/images/fura.jpg" alt="fura" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const props = defineProps<{ data: { text: Element; type: string }[] }>();
const title = computed(() => props.data.find(el => el.type === 'title'));
const htmlDescription = computed(() => props.data.filter(el => el.type === 'text'));
</script>

<style lang="scss" scoped>
.full-width-overflow {
    overflow: hidden;
}

.description-section {
    margin-top: 7.5rem;
    margin-bottom: 4.5rem;
}

.description-block {
    gap: 1.5rem;
}

.title-description {
    color: var(--brand-red-e-4003-c, #e4003c);
    font-family: Roboto;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.875rem; /* 127.778% */
}

.description-text {
    color: var(--main-black-2-b-2-b-2-b, #2b2b2b);

    /* secondary/light/22-34 */
    font-family: Roboto;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 300;
    line-height: 2.125rem; /* 154.545% */
}

.description-text::v-deep(b) {
    font-weight: 700;
}
</style>
